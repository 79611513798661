import { clientMount } from "@vp/ubik-fragment-react";
import type { MountWithRootOptions } from "@vp/ubik-fragment-types";
import { Fragment, type Props } from "../components/white-label-preference";

export const mount: MountWithRootOptions<Props> = async (
  rootElement,
  renderProps,
  rootOptions,
) => {
  return clientMount(<Fragment {...renderProps} />, rootElement, rootOptions);
};
